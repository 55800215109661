import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Hightcharts
import Highcharts from 'highcharts';
import loadExporting from 'highcharts/modules/exporting';
import VueHighcharts from 'vue-highcharts';
loadExporting(Highcharts);

// Code editor
import VueCodemirror from 'vue-codemirror'
import { basicSetup } from 'codemirror'
import { oneDark } from '@codemirror/theme-one-dark'

createApp(App)
    .use(store)
    .use(router)
    .use(VueHighcharts, { Highcharts })
    .use(VueCodemirror, {
        // optional default global options
        autofocus: true,
        disabled: false,
        indentWithTab: true,
        tabSize: 4,
        placeholder: 'Code goes here...',
        extensions: [basicSetup, oneDark]
        // ...
      })
    .mount('#app')
