<template>
    <div class="breadcrumbs">
        <div class="list">
            <div class="item">
                <span class="fas main-icon fa-home"></span>
                <router-link to="/">Dashboard</router-link>
                <span class="fas arrow fa-angle-right"></span>
            </div>
            <div class="item" v-for="(item, index) in breadcrumbs" :key="index">
                <router-link :to="getURL(index)">{{ item }}</router-link>
                <span class="fas arrow fa-angle-right"></span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    computed: {
        breadcrumbs() {
            return (this.$route.fullPath === '/') ? [] : this.$route.fullPath.split('/').slice(1);
        }
    },
    methods: {
        getURL(index) {
            let url = '';
            for (let i = 0; i <= index; i++) {
                url += '/' + this.breadcrumbs[i];
            }
            return url;
        }
    }
}
</script>
