<template>
    <div class="app-nav-panel">
        <div class="content-wrap">
            <div class="theme-trigger-wrapper hidden-xs visible-md">
                <div class="theme-trigger" :class="{'darkmode': isDarkMode}" @click="toggleTheme()">
                    <div class="roll"></div>
                </div>
            </div>
            <div class="logo">
                <img v-if="isDarkMode" src="./../assets/images/logo-alt.svg">
                <img v-else src="./../assets/images/logo.svg">
            </div>
            <div class="devider"></div>
            <div class="nav-section">
                <div class="nav-list">
                    <router-link class="nav-item" to="/">
                        <span class="icon">
                            <span class="fas fa-columns"></span>
                        </span>
                        <span class="text">Dashboard</span>
                    </router-link>
                    <router-link class="nav-item" to="/projects">
                        <span class="icon">
                            <span class="fas fa-stream"></span>
                        </span>
                        <span class="text">Projects</span>
                    </router-link>
                    <router-link class="nav-item" to="/friends">
                        <span class="icon">
                            <span class="fas fa-user-friends"></span>
                        </span>
                        <span class="text">Friends</span>
                    </router-link>
                    <router-link class="nav-item" to="/news">
                        <span class="icon">
                            <span class="fas fa-rss"></span>
                        </span>
                        <span class="text">News & Actions</span>
                    </router-link>
                </div>
            </div>
            <div class="nav-section">
                <p class="nav-title">Account</p>
                <div class="nav-list">
                    <router-link class="nav-item" to="/profile">
                        <span class="icon">
                            <span class="fas fa-user"></span>
                        </span>
                        <span class="text">Profile</span>
                    </router-link>
                    <router-link class="nav-item" to="/help">
                        <span class="icon">
                            <span class="fas fa-question"></span>
                        </span>
                        <span class="text">Help</span>
                    </router-link>
                    <button class="theme nav-item visible-xs hidden-md" @click="toggleTheme()">
                        <span class="icon">
                            <span class="fas fa-sun" v-if="isDarkMode"></span>
                            <span class="fas fa-moon" v-else></span>
                        </span>
                        <span class="text">Theme</span>
                    </button>
                </div>
            </div>
            <div class="nav-section hidden-xs visible-md" v-if="user.role == 'admin'">
                <p class="nav-title">Admin settings</p>
                <div class="nav-list">
                    <router-link class="nav-item" to="/users">
                        <span class="icon">
                            <span class="fas fa-users"></span>
                        </span>
                        <span class="text">Users</span>
                    </router-link>
                    <router-link class="nav-item" to="/logs">
                        <span class="icon">
                            <span class="fas fa-chart-bar"></span>
                        </span>
                        <span class="text">Logs</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    computed: {
        isDarkMode() {
            return this.$store.getters['isDarkMode'];
        },
        user() {
            return this.$store.getters['getUser'];  
        }
    },
    methods: {
        toggleTheme() {
            this.$store.dispatch('toggleTheme', !this.isDarkMode);
        }
    }
}
</script>
